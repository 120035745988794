import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../api/axios'
import { isExistValOfLocalStorage } from '../utils/common'

let url = 'http://p.ihycc.cn';
// let url = 'localhost:80';
// const appId = 'wx47d28e31ef38be83';
const appId = 'wxb77b549b8bfb4730';
const appId2 = 'wxb77b549b8bfb4730';
// pp.ihycc.cn

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Management',
    component: () => import('../views/Management.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 首页
    path: '/show',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 首页
    path: '/event_:name',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 首页
    path: '/event_biginfo',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '朗培天成',
    }
  },

  {
    // 登录
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 后台管理
    path: '/management',
    name: 'Management',
    component: () => import('../views/Management.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 员工加入
    path: '/employeesJoin',
    name: 'EmployeesJoin',
    component: () => import('../views/EmployeesJoin.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 添加活动
    path: '/addActivity',
    name: 'AddActivity',
    component: () => import('../views/AddActivity.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 员工管理
    path: '/employeesManagement',
    name: 'EmployeesManagement',
    component: () => import('../views/EmployeesManagement.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 战队管理
    path: '/teamManagement',
    name: 'TeamManagement',
    component: () => import('../views/TeamManagement.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 战队详情
    path: '/teamDetails',
    name: 'TeamDetails',
    component: () => import('../views/TeamDetails.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 员工数据
    path: '/employeesData',
    name: 'EmployeesData',
    component: () => import('../views/EmployeesData.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 客户数据
    path: '/customerData',
    name: 'CustomerData',
    component: () => import('../views/CustomerData.vue'),
    meta: {
      title: '朗培天成',
    }
  },
  {
    // 员工佣金详情
    path: '/employeesBill',
    name: 'EmployeesBill',
    component: () => import('../views/EmployeesBill.vue'),
    meta: {
      title: '员工佣金',
    }
  },
  {
    // 错误页面
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      title: '错误',
    }
  },
  {
    // 投诉
    path: '/complaint',
    name: 'Complaint',
    component: () => import('../views/Complaint.vue'),
    meta: {
      title: '投诉',
    }
  },
  {
    // 投诉详细内容
    path: '/ComplaintDetail',
    name: 'ComplaintDetail',
    component: () => import('../views/ComplaintDetail.vue'),
    meta: {
      title: '投诉',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // 设置标题
  document.title = to.meta.title;

  // 如果用户强行访问错误页面 清理数据
  if (to.name === 'Error') {
    next();
    return;
  }

  // 判断是否是第一次进入
  if (!isExistValOfLocalStorage('firstUrl')) {
    localStorage.setItem('firstUrl', to.fullPath);
  }

  // 判断是否存在token以及用户信息
  if (!isExistValOfLocalStorage('token') || !isExistValOfLocalStorage('userInfo')) {
    // 如果不存在 - 重新授权
    // 判断是否从授权页面回来
    if (to.query.code) {
      // 判断第一个授权是否完成
      if (!isExistValOfLocalStorage('code_1')) {
        localStorage.setItem('code_1', to.query.code);
      }
      // 判断第二个授权是否完成
      else if (!isExistValOfLocalStorage('code_2')) {
        localStorage.setItem('code_2', to.query.code);
      }
    }

    // 判断是否已经进行第一个授权 如果没有就授权
    if (!isExistValOfLocalStorage('code_1')) {
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId +
        "&redirect_uri=" + encodeURIComponent(url) +
        "&response_type=code&scope=snsapi_userinfo";
    }
    // 判断是否已经进行第二个授权 如果没有就授权
    else if (!isExistValOfLocalStorage('code_2')) {
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId2 +
        "&redirect_uri=" + encodeURIComponent(url) +
        "&response_type=code&scope=snsapi_base";
    }
    // 如果满足了上面的条件
    else {
		console.log(localStorage.getItem('firstUrl'));
      // 调用登录接口
      callLoginInterface().then(res => {
        // 设置token
        localStorage.setItem('token', res.auth_token);
        // 再次调用获取用户信息接口
        callGetUserInfoInterface().then(res_1 => {
          // 设置用户信息
          localStorage.setItem('userInfo', JSON.stringify(res_1));
			console.log(11111);
          // 判断用户是否存在支付信息
          if (!res_1.pay_openid || res_1.pay_openid === "" || res_1.pay_openid === null) {
            // 调用接口获取支付信息
            callGetUserPayInfoInterface().then(res_2 => {
              // 重新更新缓存中的用户信息
              localStorage.setItem("userInfo", JSON.stringify({
                ...JSON.parse(localStorage.getItem("userInfo")),
                pay_openid: res_2.pay_openid,
              }));
              next({ path: localStorage.getItem('firstUrl') });
            }).catch(() => {
				console.log(22222);
				next({ path: localStorage.getItem('firstUrl') });
              // 如果没有得到 跳转至错误页面
              // next({ path: "./error" });
            });
          }
          // 如果存在支付信息
          else {
            next({ path: localStorage.getItem('firstUrl') });
          }
        }).catch(() => {
			console.log(3333);
          // 如果没有得到 跳转至错误页面
          next({ path: "./error" });
        });
      }).catch(() => {
		  console.log(4444);
        // 如果没有得到 跳转至错误页面
        next({ path: "./error" });
      });
    }
  }
  // 如果是导师并且没有登录 就去登录
  else if (!isExistValOfLocalStorage("telphone") && JSON.parse(localStorage.getItem("userInfo")).admin_group_id === 1 && to.name === "Management") {
    next({ path: "/login" });
  }
  // 如果当前角色是普通会员 并且去了后台页面 没有带参数
  else if (isExistValOfLocalStorage('userInfo') && JSON.parse(localStorage.getItem('userInfo')).admin_group_id === 0 && to.name === 'Management' && (!to.query.id || !to.query.invite_id)) {
    console.log(5555);
	next({ path: "/error" })
  }
  else {
    next();
  }
});


/** 调用登录接口 */
const callLoginInterface = () => {
  return new Promise((resolve, reject) => {
    axios.post("/wechat/wxlogin", {
      wx_code: localStorage.getItem("code_1"),
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

/** 调用获取用户信息接口 */
const callGetUserInfoInterface = () => {
  return new Promise((resolve, reject) => {
    axios.post("/user/getInfo").then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

/** 调用获取用户的支付信息 */
const callGetUserPayInfoInterface = () => {
  return new Promise((resolve, reject) => {
    axios.post("/user/paylogin", {
      code: localStorage.getItem("code_2"),
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export default router
