/**
 * 通用工具类
 */

export const randomString = (len) => {
  len = len || 10;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  var maxPos = $chars.length;
  var str = '';
  for (let i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return str;
}
/**
 * 判断缓存中是否存在数据
 */
export const isExistValOfLocalStorage = (data) => {
  if (!localStorage.getItem(data)) {
    return false;
  }
  const temp = typeof localStorage.getItem(data) === 'string' ? localStorage.getItem(data) : JSON.parse(localStorage.getItem(data));
  if (temp === null || temp === "" || temp === undefined || temp === 'null' || temp === 'undefined' || temp === [] || temp === {}) {
    return false;
  }
  return true;
}