import axios from 'axios';
import qs from 'qs'; 


// 超时时间（ms）
// axios.defaults.timeout = 5000 * 1000;
// axios请求开启cookie，支持跨域请求携带cookie
// axios.defaults.withCredentials = true;


// axios 请求头
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers['Authorization'] = localStorage.getItem('token') || '';
axios.defaults.headers.post['Content-Type'] = 'application/json';
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


/**
 * 配置请求的参数处理、以及请求拦截
 */
axios.interceptors.request.use(function(config) {
    config.headers['Authorization'] = localStorage.getItem('token') || '';
    
    // 转换JSON
    config.paramsSerializer = function(params) {
        return JSON.stringify(params, { indices: false });
    };
    
    return config;
}, function(error) {
    // 请求错误做的一些操作
    return Promise.reject(error);
});


/**
 * 配置请求的响应处理
 */
axios.interceptors.response.use(
    response => {
        if(response.status === 200) {
            // 判断登陆是否过期
            if(response.data.code === -1) {
                // 清空token
                localStorage.removeItem("token");
                window.location.reload();
                return Promise.reject(response.data);
            }
            // 判断响应结果
            if(response.data.code === 200) {
                return Promise.resolve(response.data.data);
            }
            return Promise.reject(response.data.msg);
        }
        else {
            return Promise.reject(response.data.data);
        }
    },
    error => {
        return Promise.reject(error.data.data);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function get(url, params){    
    return new Promise((resolve, reject) =>{   
        axios.get("http://pp.ihycc.cn/api.php" + url + "?" + qs.stringify(params))
        .then(res => {
            resolve(res);
        })
        .catch(err =>{
            reject(err);       
        })    
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post("http://pp.ihycc.cn/api.php" + url, params)
        .then(res => {
            resolve(res);
        })
        .catch(err =>{
            reject(err)
        })
    });
}


// 导出配置好的对象
export default {
    get,
    post
};